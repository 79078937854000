import {
  Autocomplete,
  Checkbox,
  TextField,
  createFilterOptions,
} from "@mui/material"
import { type ReactNode, useMemo } from "react"
import { ChevronDown } from "react-feather"
import { Controller, type FieldPath, type FieldValues } from "react-hook-form"
import { isPlainObject } from "remeda"
import type { RequireExactlyOne } from "type-fest"
import { useFieldRoleConf } from "../../../context"

export type ValueFormat = "optionObject" | "optionValue"
export interface FormSearchFieldProps<
  Option,
  TFieldValues extends FieldValues = FieldValues,
> {
  name: FieldPath<TFieldValues>
  label: string
  options: SearchOption<Option>[]
  multiple?: boolean
  required?: boolean
  keepOriginalOrder?: boolean
  size?: "small" | "medium"
  /** Defines the format used in value & onChange */
  valueFormat?: ValueFormat
  defaultValue?: SearchOption<Option>[] | Option | string
}

/**
 * Search: basically a select with autocomplete
 * NB: The list is not async (but could be ? What if options is a Promise ?)
 */
export const FormSearchField = <
  Option extends string | number,
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  label,
  options,
  multiple = false,
  required = false,
  keepOriginalOrder = false,
  valueFormat = "optionObject",
  size,
  defaultValue,
  ...otherProps
}: FormSearchFieldProps<Option, TFieldValues>) => {
  const filter = useMemo(() => createFilterOptions<SearchOption<Option>>(), [])
  const sortedOptions = useMemo(
    () =>
      keepOriginalOrder
        ? options
        : [...options].sort((a, b) => a.label.localeCompare(b.label)),
    [options, keepOriginalOrder],
  )
  // RoleConf
  const fieldConf = useFieldRoleConf(name)
  return (
    <Controller
      name={name}
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      defaultValue={defaultValue as any}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete<SearchOption<Option>, typeof multiple, boolean, false>
          id={name}
          {...field}
          {...fieldConf}
          {...otherProps}
          value={parseFieldValue(field.value || defaultValue, multiple)}
          onChange={(_event, newValue) => {
            // Multiple
            if (Array.isArray(newValue)) {
              const arrayValue = (
                newValue.length > 0 ? newValue : defaultValue
              ) as SearchOption<Option>[]
              field.onChange(
                arrayValue?.map((newValueEl) =>
                  getFieldValue(newValueEl, valueFormat),
                ),
              )
            } else {
              field.onChange(getFieldValue(newValue || defaultValue, valueFormat))
            }
          }}
          filterOptions={filter}
          options={sortedOptions}
          getOptionLabel={(option) =>
            sortedOptions.find(
              (opt) => getOptionValue(opt) === getOptionValue(option),
            )?.label || ""
          }
          renderOption={(props, option, { selected }) => {
            const { key, ...otherProps } = props
            return (
              <li key={key} {...otherProps}>
                {!!multiple && <Checkbox checked={selected} />}
                {option.formattedLabel || option.label}
              </li>
            )
          }}
          ChipProps={{ variant: "outlined", color: "primary" }}
          isOptionEqualToValue={(option, currValue) =>
            getOptionValue(option) === getOptionValue(currValue)
          }
          selectOnFocus
          clearOnBlur
          multiple={multiple}
          popupIcon={<ChevronDown />}
          disableClearable={multiple ? !field.value?.length : !field.value}
          disableCloseOnSelect={multiple}
          noOptionsText="Aucun résultat"
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              helperText={error?.message}
              required={required}
            />
          )}
          size={size}
        />
      )}
    />
  )
}

/**
 * Made for backend lists format
 * Some use `code` others `value`
 */
export type SearchOption<T> = RequireExactlyOne<
  {
    formattedLabel?: ReactNode
    label: string
    value: T
    code: T
  },
  "value" | "code"
>

const parseFieldValue = <T,>(value: T, isMultiple: boolean) => {
  if (isMultiple) {
    if (Array.isArray(value)) {
      return value
    }
    return value ? [value] : []
  }
  return value ?? ""
}

/** Factorize option.value / option.code access */
const getOptionValue = <T,>(option: Partial<SearchOption<T>> | T) => {
  if (isPlainObject(option)) {
    return option.value || option.code
  }
  return option
}

const getFieldValue = <T,>(option: SearchOption<T> | T, valueFormat: ValueFormat) =>
  valueFormat === "optionObject" && isPlainObject(option)
    ? option
    : getOptionValue(option)
