import {
  Box,
  Stack,
  Tooltip,
  type TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material"
import { type ChipVariation, TinyChip } from "@pharmupp/p3-front-commons"
import { Check, MoreHorizontal } from "react-feather"

export interface OfferTag {
  label: string
  chipVariation?: ChipVariation
  checked?: boolean
}

export interface OfferTagList {
  tags: OfferTag[]
  maxTags?: number
  gap?: number
}

export const OfferTagList = ({
  tags,
  maxTags = tags.length,
  gap = 1,
}: OfferTagList) => {
  const visibleTags = tags.slice(0, maxTags)
  const hiddenTags = tags.slice(maxTags)

  return (
    <Stack direction="row" gap={gap} flexWrap="wrap">
      {visibleTags.map((tag) => OfferTag(tag))}
      {hiddenTags.length > 0 && (
        <CustomTooltip
          arrow
          title={<Stack spacing={1}>{hiddenTags.map((tag) => OfferTag(tag))}</Stack>}
        >
          <TagEllipsis
            label={<MoreHorizontal size={11} />}
            role="button"
            tabIndex={-1}
          />
        </CustomTooltip>
      )}
    </Stack>
  )
}

const OfferTag = (tag: OfferTag) => (
  <TinyChip
    key={tag.label}
    label={tag.label}
    variation={tag.chipVariation}
    icon={
      tag.checked ? (
        <CheckWrapper>
          <Check size={11} />
        </CheckWrapper>
      ) : undefined
    }
    sx={{
      width: "fit-content",
      flexDirection: "row-reverse",
      gap: 0.5,
      "& .MuiChip-iconMedium": {
        marginRight: "2px",
        marginLeft: "0px",
      },
    }}
  />
)

const CheckWrapper = styled(Box)(({ theme: { palette } }) => ({
  width: "18px",
  height: "18px",
  border: `1px solid ${palette.purple.dark3}`,
  backgroundColor: "white",
  color: palette.purple.dark3,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "100px",
}))

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: theme.spacing(1.2),
  },
}))

const TagEllipsis = styled(TinyChip)(({ theme: { palette } }) => ({
  width: 15,
  padding: 0,
  cursor: "pointer",
  "& .MuiChip-label": { display: "flex" },
  backgroundColor: palette.common.lightBlue,
  borderColor: palette.common.lightBlue,
  color: palette.common.blue,
}))
