import {
  Box,
  Button,
  Card,
  CardActionArea,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material"
import { Check } from "react-feather"
import type { TimeType } from "../api"
import { openOffer } from "../common"
import { OfferTagList } from "../common/OfferTagList"
import type { Offer } from "../types"

interface OfferCard {
  offer: Offer
  showLabLogo?: boolean
  timeType?: "IN_PROGRESS" | "COMING_SOON"
  isAdmin?: boolean
}

export const OfferCard = ({
  offer,
  showLabLogo = true,
  timeType = "IN_PROGRESS",
  isAdmin = false,
}: OfferCard) => {
  const cardHeight = showLabLogo ? "350px" : "290px"

  const CardContent = (
    <Content
      offer={offer}
      isAdmin={isAdmin}
      showLabLogo={showLabLogo}
      timeType={timeType}
    />
  )

  return (
    <Card key={offer.id} component={Box} height={cardHeight}>
      {timeType !== "COMING_SOON" ? (
        <CardActionArea
          component={Box}
          height="100%"
          onClick={() => openOffer(offer)}
        >
          {CardContent}
        </CardActionArea>
      ) : (
        CardContent
      )}
    </Card>
  )
}

const Content = ({
  offer,
  showLabLogo = true,
  timeType = "IN_PROGRESS",
  isAdmin = false,
}: OfferCard) => (
  <Stack height="100%">
    {/* CHIPS */}
    <Box sx={{ minHeight: 40 }} m={1}>
      <OfferTagList tags={offer.tags} maxTags={3} gap={0.8} />
    </Box>
    <Stack justifyContent="space-between" px={2} pb={3} flex={1}>
      <Stack alignItems="flex-start">
        {/* LOGO */}
        {!!showLabLogo && (
          <CardLogo src={offer.labLogoUrl} height="71px" loading="lazy" />
        )}
        {/* TITLE */}
        <CardTitle mt={2} mb={1}>
          {offer.title}
        </CardTitle>
      </Stack>
      <Stack>
        <Box height={(theme) => theme.spacing(3)}>
          {!!offer.type && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.3}
              color={offer.type.color}
              height={(theme) => theme.typography.pxToRem(14)}
            >
              <offer.type.Icon style={{ height: "inherit" }} />
              <CardOfferKind>{offer.type.label}</CardOfferKind>
            </Stack>
          )}
        </Box>
        <CardOfferDates>
          {getDisplayedDateInfo(
            timeType,
            offer.startDateFrenchFormat,
            offer.endDateFrenchFormat,
          )}
        </CardOfferDates>
        {timeType !== "COMING_SOON" && (
          <Stack direction="row" alignItems="center" spacing={1} mt={3}>
            {!!offer.lastOrderDate && (
              <AlreadyOrderedIcon orderDate={offer.lastOrderDate} />
            )}
            <Button variant="contained" size="small" sx={{ pointerEvents: "none" }}>
              {isAdmin ? (
                "Consulter"
              ) : (
                <>{offer.isPreorder ? "Précommander" : "Commander"}</>
              )}
            </Button>
            {!!offer.maxItems && (
              <Typography
                fontSize={(theme) => theme.typography.pxToRem(10)}
                color="grey.light"
                sx={{ textDecoration: "underline" }}
              >
                Nb max : {offer.maxItems}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  </Stack>
)

const CardLogo = styled("img")({
  maxWidth: "100%",
  objectFit: "contain",
})

const CardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: "normal",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  /* number of lines to show */
  WebkitLineClamp: "5",
  lineClamp: "5",
}))

const CardOfferKind = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(11),
  textTransform: "uppercase",
}))
const CardOfferDates = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  fontSize: theme.typography.pxToRem(11),
}))
const AlreadyOrderedIcon = ({ orderDate }: { orderDate: string }) => (
  <Tooltip title={`Déjà commandé le ${orderDate}`} arrow>
    <Box
      bgcolor="success.main"
      width={24}
      height={24}
      borderRadius="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Check height={18} width={18} color="#fff" />
    </Box>
  </Tooltip>
)

export const getDisplayedDateInfo = (
  timeType: TimeType,
  startDate: string,
  endDate: string,
) =>
  timeType === "IN_PROGRESS" ? `Jusqu'au ${endDate}` : `À partir du ${startDate}`
