import { Box, Stack, Typography, styled } from "@mui/material"
import { HalfCircularProgress, formatNumber } from "@pharmupp/p3-front-commons"

export interface LoyaltyGaugeProps {
  title: string
  description: string
  currentValue?: number
  maxValue?: number
  lastYearValue?: number
  unit: string
  color: string
}
export const LoyaltyGauge = ({
  title,
  description,
  currentValue,
  maxValue,
  lastYearValue,
  unit,
  color,
}: LoyaltyGaugeProps) => {
  const percentage = getGaugePercentage(currentValue, maxValue)

  return (
    <Wrapper>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        {/* INFOS */}
        <Box>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Kpi>
            {currentValue === undefined
              ? "-"
              : `${formatNumber(currentValue)} ${unit}`}
          </Kpi>
        </Box>
        {/*  CHART */}
        <HalfCircularProgress
          size={180}
          value={percentage}
          color={color}
          bottomCaption={
            <Typography variant="caption" color={color}>
              {lastYearValue ? "Total N-1" : "Objectif"} :&nbsp;
              {`${formatNumber(lastYearValue || maxValue)} ${unit}`}
            </Typography>
          }
        />
      </Stack>
    </Wrapper>
  )
}

export function getGaugePercentage(currentValue?: number, maxValue?: number) {
  return currentValue !== undefined && maxValue !== undefined && maxValue !== 0
    ? Math.floor((currentValue * 100) / maxValue)
    : 0
}

const Wrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  flexGrow: 1,
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  textTransform: "uppercase",
  color: theme.palette.primary.dark1,
}))
const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.pxToRem(12),
  [theme.breakpoints.up("md")]: {
    fontSize: theme.typography.pxToRem(14),
  },
}))
const Kpi = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(32),
  [theme.breakpoints.up("md")]: {
    fontSize: theme.typography.pxToRem(36),
  },
}))
