import {
  type ReferentialCode,
  type UploadedFile,
  api,
  useQuery,
} from "@pharmupp/p3-front-commons"
import type { PartnershipName } from "../../laboratoryReferential"
import type { CatalogType } from "../../laboratoryReferential"

export const useAdhLabReferentialApi = () => {
  const { data: referential } = useQuery({
    queryKey: ["laboratories", "list", "adherent", "referential"],
    queryFn: () =>
      api.get<ApiAdhLabListReferential>("/api/laboratories/referential/adherent"),
  })

  return {
    referential,
  }
}

export interface ApiAdhLabListReferential {
  partnershipType: ReferentialCode<string>[]
  categories: ReferentialCode<string>[]
  channels: ReferentialCode<string>[]
}

export interface ApiAdherentLaboratory {
  id: number
  name: string
  catalogType: CatalogType
  availableOffersCount: number
  logo: UploadedFile
  categories?: string[]
  partnershipType: PartnershipName
}
