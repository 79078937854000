import {
  type ReferentialEnum,
  referentialEnumToReferentialCodeList,
} from "@pharmupp/p3-front-commons"

export type NegotiationStatusName = keyof typeof negotiationStatusEnum
export const negotiationStatusEnum = {
  AGREEMENT_TERMINATED: { label: "Rupture d’accord" },
  PROSPECT: { label: "Prospect" },
  IN_PROGRESS: { label: "En cours" },
  UNSTABLE_TERMS: { label: "Tension avec le labo" },
  FINALIZED: { label: "Finalisé" },
  NOT_APPLICABLE: { label: "Néant" },
} as const satisfies ReferentialEnum
export const negotiationStatusReferential =
  referentialEnumToReferentialCodeList(negotiationStatusEnum)

export type PartnershipName = keyof typeof partnershipEnum
export const partnershipEnum = {
  NONE: { label: "Non partenaire" },
  CLASSIC: { label: "Classique" },
  PREMIUM: { label: "Premium" },
  PRIVILEGED: { label: "Privilégié" },
} as const satisfies ReferentialEnum
export const partnershipReferential =
  referentialEnumToReferentialCodeList(partnershipEnum)

export type CategoriesName = keyof typeof categoriesEnum
export const categoriesEnum = {
  ORTHOPEDICS: { label: "Orthopédie" },
  COMPRESSION: { label: "Compression" },
  NUTRITION: { label: "Nutrition" },
  INCONTINENCE: { label: "Incontinence" },
  GENERICS: { label: "Génériques" },
  BIOSIMILARS: { label: "Biosimilaires" },
  OTC_ADVICE: { label: "OTC / Conseil" },
  WHITE_PRODUCT_LINE: { label: "Gamme Blanche" },
  MOM_AND_BABY: { label: "Bébé & maman" },
  VACCINATION: { label: "Vaccination grippe" },
  VETERINARY: { label: "Vétérinaire" },
  DIABETES: { label: "Diabète" },
  DERMO_COSMETICS: { label: "Dermo-cosmétique" },
  NATURALNESS: { label: "Naturalité" },
  ONCOLOGY: { label: "Oncologie" },
  HOMECARE: { label: "MAD" },
} as const satisfies ReferentialEnum
export const categoriesReferential =
  referentialEnumToReferentialCodeList(categoriesEnum)

export type StatusesName = keyof typeof statusesEnum
export const statusesEnum = {
  ACTIVE: { label: "Actif", color: "success.main" },
  DEACTIVATED: { label: "Inactif", color: "error.main" },
} as const satisfies ReferentialEnum<{ color: string }>
export const statusesReferential = referentialEnumToReferentialCodeList(statusesEnum)

export type MarketShareCategoryName = keyof typeof marketShareCategoriesEnum
export const marketShareCategoriesEnum = {
  GENERICS: { label: "Génériques", order: 1 },
  ORTHOPEDICS: { label: "Orthopédie", order: 2 },
  COMPRESSION: { label: "Compression", order: 3 },
  NUTRITION: { label: "Nutrition", order: 4 },
  WHITE_PRODUCT_LINE: { label: "Gamme Blanche", order: 5 },
  INCONTINENCE: { label: "Incontinence", order: 6 },
  BIOSIMILARS: { label: "Biosimilaires", order: 7 },
  VACCINATION: { label: "Vaccination", order: 8 },
} as const satisfies ReferentialEnum<{ label: string; order: number }>

export type CatalogName = keyof typeof catalogLabelEnum
export const catalogLabelEnum = {
  fdl: { label: "FDL" },
  sagitta: { label: "Ma Boutique" },
  coalia: { label: "Coalia" },
  sdav: { label: "SDAV" },
} as const satisfies ReferentialEnum
export type CatalogType = Record<CatalogName, boolean>

export const challengeCategoriesEnum = {
  ORTHOPEDICS: { label: "Orthopédie" },
  COMPRESSION: { label: "Compression" },
  NUTRITION: { label: "Nutrition" },
  INCONTINENCE: { label: "Incontinence" },
  GENERICS: { label: "Génériques" },
  BIOSIMILARS: { label: "Biosimilaires" },
  WHITE_PRODUCT_LINE: { label: "Gamme Blanche" },
  VACCINATION: { label: "Vaccination grippe" },
} as const satisfies ReferentialEnum
export const challengeCategoriesReferential = referentialEnumToReferentialCodeList(
  challengeCategoriesEnum,
)
